/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Presentation page sections
// import Counters from "pages/Presentation/sections/Counters";
// import Information from "pages/Presentation/sections/Information";
// import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
// import Testimonials from "pages/Presentation/sections/Testimonials";
// import Download from "pages/Presentation/sections/Download";

// Presentation page components
// import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
// import Lena19 from "assets/images/LAA/Lena-19.jpg";
import Lena37 from "assets/images/LAA/Lena-37.jpg";
// import Lena39 from "assets/images/LAA/Lena-39.jpg";
import PaintKit from "assets/images/LAA/paint-kit.jpg";
import Lena54 from "assets/images/LAA/Lena-54.jpg";
// import Lena29 from "assets/images/LAA/Lena-29.jpg";
// import Lena27 from "assets/images/LAA/Lena-27.jpg";
// import Lena11 from "assets/images/LAA/Lena-11.jpg";
// import PaintTray from "assets/images/LAA/paint-tray.jpg";
// import WallMounting from "assets/images/LAA/wall-mounting.jpg";
import MasonJar from "assets/images/LAA/mason-jar.jpg";

function Home() {
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${Lena37})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container sx={{ mt: 6 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <TransparentBlogCard
                sx={{ display: "flex" }}
                image={Lena54}
                title="Meet Lena"
                description="The story behind the art."
                action={{
                  type: "internal",
                  route: "/about",
                  color: "secondary",
                  label: "About the Artist",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} />
            <Grid item xs={12} lg={6}>
              <TransparentBlogCard
                sx={{ display: "flex" }}
                image={MasonJar}
                title='"Art enables us to find ourselves and lose ourselves at the same time."'
                description="– Thomas Merton"
                action={{
                  type: "internal",
                  route: "/about",
                  color: "secondary",
                  label: "Portfolio",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Card>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${PaintKit})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* <Counters /> */}
        {/* <Information /> */}
        {/* <DesignBlocks /> */}
      </Card>
      {/* <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${Lena11})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          display: "grid",
          placeItems: "center",
        }}
      /> */}
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Pages />
        {/* <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container> */}
        {/* <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Getting Started"
                description="Check the possible ways of working with our product and the necessary files for building your own project."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
                  label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="precision_manufacturing"
                title="Plugins"
                description="Get inspiration and have an overview about the plugins that we used to create the Material Kit."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/overview/datepicker/",
                  label: "Read more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="apps"
                title="Components"
                description="Material Kit is giving you a lot of pre-made components, that will help you to build UI's faster."
                action={{
                  type: "external",
                  route: "https://www.creative-tim.com/learning-lab/react/alerts/material-kit/",
                  label: "Read more",
                }}
              />
            </Grid>
          </Grid>
        </Container> */}
        {/* <Testimonials /> */}
        {/* <Download /> */}
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Follow me on social
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://www.etsy.com/shop/LenaAndersonArt"
                  target="_blank"
                  color="etsy"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-etsy" />
                  &nbsp;Buy on Etsy
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.instagram.com/lenaandersonart/"
                  target="_blank"
                  color="instagram"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-instagram" />
                  &nbsp;follow me
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="https://www.pinterest.com/lenaandersonart"
                  target="_blank"
                  color="pinterest"
                >
                  <i className="fab fa-pinterest" />
                  &nbsp;Pin it
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
