/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
// import profilePicture from "assets/images/LAA/Lena-16-web.jpg";
import profilePicture from "assets/images/LAA/Lena-19-web.jpg";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Lena Anderson" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">Lena Anderson</MKTypography>
                <MKButton variant="outlined" color="info" size="small">
                  Follow
                </MKButton>
              </MKBox>
              <MKTypography variant="body1" fontWeight="light" color="text">
                Me, me, I. Myself, I me. I, I, I, I, & me. Myself Me Me Mine. My me & I.
                <br />
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                deserunt mollit anim id est laborum.
                <br />
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Nunc congue nisi vitae suscipit tellus
                mauris a. Pharetra convallis posuere morbi leo urna molestie at elementum. Non nisi
                est sit amet facilisis magna etiam tempor. Imperdiet nulla malesuada pellentesque
                elit eget gravida cum. Semper risus in hendrerit gravida rutrum quisque non.
                Dignissim cras tincidunt lobortis feugiat vivamus. Turpis massa tincidunt dui ut
                ornare lectus sit amet est. Nulla facilisi etiam dignissim diam quis enim lobortis.
                Pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet. In ornare
                quam viverra orci sagittis eu. Auctor neque vitae tempus quam pellentesque nec nam
                aliquam sem. Vel pharetra vel turpis nunc. Pellentesque massa placerat duis
                ultricies. Nunc aliquet bibendum enim facilisis. Metus dictum at tempor commodo
                ullamcorper a lacus vestibulum. Nunc sed augue lacus viverra vitae congue eu. Vitae
                proin sagittis nisl rhoncus mattis rhoncus urna neque.
                {/* <MKTypography
                  component="a"
                  href="#"
                  variant="body1"
                  fontWeight="light"
                  color="info"
                  mt={3}
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                >
                  More about me <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography> */}
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
