import Contact from "pages/AboutTheArtist/sections/Contact";
import MKBox from "components/MKBox";
import Container from "@mui/material/Container";

export default function ContactPage() {
  return (
    <>
      <MKBox component="section" py={2}>
        <Container>
          <Contact
            position="relative"
            // style={{ zIndex: 2 }}
          />
        </Container>
      </MKBox>
    </>
  );
}
