/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
// import data from "pages/Presentation/sections/data/pagesData";
// import Lena11 from "assets/images/LAA/Lena-11.jpg";
import Lena27 from "assets/images/LAA/Lena-27.jpg";

function Pages() {
  const cardActionStyles = {
    display: "flex",
    alignItems: "center",
    width: "max-content",

    "& .material-icons, .material-icons-round,": {
      transform: `translateX(2px)`,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
      {
        transform: `translateX(6px)`,
      },
  };
  // const renderData = data.map(({ image, name, route }) => (
  //   <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
  //     <Link to={route}>
  //       <ExampleCard image={image} name={name} display="grid" minHeight="auto" />
  //     </Link>
  //   </Grid>
  // ));

  return (
    <MKBox component="section" py={6}>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {/* {renderData} */}
              <Link to="/contact-us">
                <ExampleCard image={Lena27} name="" display="grid" minHeight="auto" />
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                Looking for something specific?
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                {/* prettier-ignore-start */}
                Or maybe you need a gift for that special someone? Reach out and let me know what
                you&apos;re interested in! I&apos;d love to help bring your idea to life.
                {/* prettier-ignore-end */}
              </MKTypography>
              <MKTypography
                component={Link}
                to="/contact-us"
                variant="body2"
                fontWeight="regular"
                color="secondary"
                textTransform="capitalize"
                sx={cardActionStyles}
              >
                Commission your own
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pages;
