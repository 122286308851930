import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

/* eslint-disable no-underscore-dangle */
// const config = window.__CONFIG__;
// delete window.__CONFIG__;
/* eslint-enable no-underscore-dangle */

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
